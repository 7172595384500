import React from 'react'
import "./jackpot.css";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
const SpinGameLayout = ({ children }) => {
    const history = useHistory();

    return (
        <div className='SpinGameLayout min-w-screen min-h-screen bg-slate-300' >
            {children}
            <button className='bg-transparent border border-yellow-800 px-4 py-2 rounded-md absolute left-2 top-2' onClick={() => history.push('/')}>
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-arrow-left-from-line"><path d="m9 6-6 6 6 6" /><path d="M3 12h14" /><path d="M21 19V5" /></svg>
            </button>
        </div>
    )
}

export default SpinGameLayout