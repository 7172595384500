// const createRoom ='create_room';
export const SocketcreateRoom = "createRoom";
export const SocketjoinRoom = "joinRoom";
export const SocketCheckMs = "checkMs";

export const ScoketleaveRoom = "leaveRoom";

export const SocketreJoinRoom = "reJoinRoom";
export const SocketOnPlayerEnterRoom = "OnPlayerEnterRoom";
export const SocketOnPlayerLeftRoom = "OnPlayerLeftRoom";
export const SocketPlayerList = "PlayerList";
export const SocketRoomListing = "RoomListing";
export const SocketOnRoomListUpdate = "OnRoomListUpdate";

//Raise Event
export const SocketraiseEvent = "raiseEvent";

//Room Properties
export const SocketsetRoomProperties = "setRoomProperties";
export const SocketgetRoomProperties = "getRoomProperties";

export const setRoomPropertiesForLobby = "setRoomPropertiesForLobby";
export const getRoomPropertiesForLobby = "getRoomPropertiesForLobby";

export const SocketOnRoomPropertiesChange = "OnRoomPropertiesChange";
export const SocketchangeRoomBaseProperty = "changeRoomBaseProperty";

//LocalKeys

// const createRoom ='create_room';
export const OnConnectToMaster = "localOnConnectToMaster";
export const createRoom = "localCreateRoom";
export const onCreateRoom = "localOnCreateRoom";
export const onCreateRoomFailed = "localOnCreateRoomFailed";

export const joinRoom = "localjoinRoom";
export const onJoinRoom = "localonjoinRoom";
export const onJoinRoomFailed = "localonJoinRoomFailed";

export const reJoinRoom = "localreJoinRoom";

export const LeaveRoom = "localLeaveRoom";
export const OnLeaveRoom = "localOnLeaveRoom";
export const OnLeaveRoomFailed = "localOnLeaveRoomFailed";

export const OnPlayerEnterRoom = "localOnPlayerEnterRoom";
export const OnPlayerLeftRoom = "localOnPlayerLeftRoom";

export const PlayerList = "localPlayerList";
export const OnPlayerList = "localOnPlayerList";

export const RoomListing = "localRoomListing";
export const OnRoomListing = "localOnRoomListing";
export const OnLocalRoomListUpdate = "OnLocalRoomListUpdate";

//Raise Event
export const raiseEvent = "localraiseEvent";
export const onRaiseEvent = "localOnRaiseEvent";

//Room Properties
export const setRoomProperties = "localsetRoomProperties";
export const getRoomProperties = "localgetRoomProperties";
export const onGetRoomProperties = "localonGetRoomProperties";
export const onLocalGetRoomProperties = "localonGetRoomProperties";

export const localSetRoomProperties = "localSetRoomProperties";
export const localGetRoomProperties = "localGetRoomProperties";
export const OnRoomPropertiesChange = "localOnRoomPropertiesChange";
export const localchangeRoomBaseProperty = "localchangeRoomBaseProperty";

export const setLocalWinner = "setLocalWinner";

//Game Events
export const StartGame = "localStartGame";

// NEW GAME EVENTS

export const SOCKET_EVENTS = {
  CONNECT: "connection",
  DISCONNECT: "disconnect",
  CREATE_NEW_GAME: "TEENPATTI_create-new-game",
  JOIN_GAME: "TEENPATTI_joinRoom",
  LEAVE_GAME: "TEENPATTI_leaveRoom",
  PLAYER_EXIT: "TEENPATTI_PLAYER_EXIT",
  PLAYER_JOIN_GAME: "TEENPATTI_player-join-game",
  PLAYER_JOIN_GAME_IN_MID: "TEENPATTI_player-join-game_in_middle",
  ERROR: "TEENPATTI_error",
  START_GAME: "TEENPATTI_START-GAME",
  UPDATE_MOVE: "TEENPATTI_UPDATE-MOVE",
  UPDATE_FEN: "TEENPATTI_UPDATE-FEN",
  GAME_RESULT: "TEENPATTI_GAME-RESULT",
  MUTUAL_CONFORMATION_REQUEST: "TEENPATTI_MUTUAL_CONFORMATION_REQUEST",
  MUTUAL_CONFORMATION_RESPONSE: "TEENPATTI_MUTUAL_CONFORMATION_RESPONSE",
  REJOIN_GAME: "TEENPATTI_REJOIN_GAME",
  CREATE_QUICK_PLAY: "TEENPATTI_CREATE_QUICK_PLAY",
  TIMER: "TEENPATTI_TIMER",
  QUIT_GAME: "TEENPATTI_QUIT_GAME",
  SINGLE_PLAYER_WATING: "SinglePlayerWaiting",
  PLAYER_ENTER: "TEENPATTI_PLAYER_ENTER",
  LEAVE_GAME_ON_LOBBY: "TEENPATTI_LEAVE_GAME",
  REMOVE_USER_FROM_SOCKET: "TEENPATTI_REMOVE_USER_FROM_SOCKET",

  //  game steps
  COLLECT_BOOT_AMOUNT: "COLLECT_BOOT_AMOUNT",
  DEALING_CARDS: "DEALING_CARDS",

  // game actions
  GAME_ACTION_BET: "GAME_ACTION_BET",
  GAME_ACTION_PACK: "GAME_ACTION_PACK",
  GAME_ACTION_SIDESHOW: "GAME_ACTION_SIDESHOW",
  GAME_ACTION_SIDESHOW_accept: "GAME_ACTION_SIDESHOW_accept",
  GAME_ACTION_SIDESHOW_reject: "GAME_ACTION_SIDESHOW_reject",
  GAME_ACTION_SEEN: "GAME_ACTION_SEEN",
  SOCKET_EVENTS_ERROR: "SOCKET_EVENT_ERROR",
  GAME_CLOSED: "GAME_CLOSED",

  // Free play
  START_FREE_PLAY: "START_FREE_PLAY",

  // quick Play
  QUICK_PLAY_JOIN_REJECT: "QUICK_PLAY_JOIN_REJECT",
  QUICK_PLAY_CLOSE_ROOM: "QUICK_PLAY_CLOSE_ROOM",
};

export const GAME_STATUS = {
  NOT_STARTED: "not_started",
  WAITING: "waiting",
  COLLECTING: "collecting",
  DEALING: "dealing",
  BETTING: "betting",
  ALL_PACKED: "all_packed",
  ALL_IN: "all-in",
  PODLIMITEXID: "podLimitExid",
  CHALLIMITEXID: "chalLimitExid",
};

export const playerAction = {
  BET: "bet",
  SIDESHOW: "side_Show",
  PACK: "pack",
  ACCEPTSIDESHOW: "accept_side_show",
  REJECTSIDESHOW: "reject_side_show",
  SHOWDOWN: "show_down",
  SENDMESSAGE: "send_message",
  LOW_BALANCE: "Low_Balance",
};
