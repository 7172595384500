import { createSlice } from "@reduxjs/toolkit";
import { setData } from "../../Connection/storage";

const initialState = {
  gameMode: null,
  gameFee: 1,
  board_type: "default",
  userData: null,
  whoMoved: null,
  is_promoted: false,
  w_Time: -1,
  b_Time: -1,
  PLAYERS: [],
  game_detail: [],
  JOINED_PLAYERS_COUNT: 0,
  CONNECTED_PLAYERS_COUNT: 0,
  playerLeft: null,
  chessBoardLoading: true,
};

const gameState = createSlice({
  name: "game",
  initialState,
  reducers: {
    RESET_STATE: (state) => {
      state.gameMode = null;
      state.userData = null;
      state.is_promoted = false;
      state.gameFee = 1;
      state.PLAYERS = [];
      state.game_detail = [];
      state.playerLeft = null;
    },
    RESET_PLAYER: (state) => {
      state.PLAYERS = [];
    },
    SET_GAME_MODE: (state, action) => {
      state.gameMode = action.payload;
      setData("gameMode", action.payload);
    },
    SET_JOINED_USER: (state, action) => {
      state.userData = action.payload;
    },
    SET_ROOM_PLAYERS: (state, action) => {
      state.PLAYERS = action.payload;
      state.JOINED_PLAYERS_COUNT = action.payload.length;
      state.CONNECTED_PLAYERS_COUNT = action.payload.filter(
        (player) => player.isConnected
      ).length;
    },
    SET_GAME_FEE: (state, action) => {
      state.gameFee = action.payload;
    },
    SET_GAME_DETAIL: (state, action) => {
      state.game_detail = action.payload;
    },
    setWhoMovedAndTurn: (state, action) => {
      state.whoMoved = action.payload;
    },
    setIsPromoted: (state, action) => {
      state.is_promoted = action.payload;
    },
    UPDATE_TIME: (state, action) => {
      const { side, time } = action?.payload;
      state[`${side}_Time`] = time;
    },
    setPlayerLeft: (state, action) => {
      state.playerLeft = action.payload;
    },

    setChessBoardLoading: (state, action) => {
      state.chessBoardLoading = action.payload;
    },
  },
});

export default gameState.reducer;
export const gameActions = gameState.actions;
