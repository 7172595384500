import ApiConfig, { baseurl } from "../ApiConfig/ApiConfig.js";
import axios from "axios";

export const getSpinStatus = async () => {
  try {
    return await axios({
      method: "GET",
      url: ApiConfig?.getSpinStatusApi,
      headers: {
        token: window.sessionStorage.getItem("token"),
      },
    });
  } catch (error) {
    console.log(error);
    return error?.response;
  }
};

export const getSpins = async () => {
  try {
    return await axios({
      method: "GET",
      url: ApiConfig?.getUserSpin,
      headers: {
        token: window.sessionStorage.getItem("token"),
      },
    });
  } catch (error) {
    console.log(error);
    return error?.response;
  }
};

export const getAllHistoryApi = async (page = 1) => {
  try {
    return await axios({
      method: "GET",
      url: `${baseurl}/api/v1/jackpot/jackpot-history?page=${page}&limit=20`,
      headers: {
        token: window.sessionStorage.getItem("token"),
      },
    });
  } catch (error) {
    console.log(error);
    return error?.response;
  }
};

export const getJackpotLeaderBoardData = async (page = 1) => {
  try {
    return await axios({
      method: "GET",
      url: ApiConfig?.jackpostLeadboard + `?page=${page}&limit=20`,
      headers: {
        token: window.sessionStorage.getItem("token"),
      },
    });
  } catch (error) {
    console.log(error);
    return error?.response;
  }
};

export const getjackpotHistory = async () => {
  try {
    return await axios({
      method: "GET",
      url: ApiConfig?.jackpotHistory,
      headers: {
        token: window.sessionStorage.getItem("token"),
      },
    });
  } catch (error) {
    console.log(error);
    return error?.response;
  }
};

export const getPotAmountApi = async () => {
  try {
    return await axios({
      method: "GET",
      url: ApiConfig?.getSpinGamePotAmount,
      headers: {
        token: window.sessionStorage.getItem("token"),
      },
    });
  } catch (error) {
    console.log(error);
    return error?.response;
  }
};
export const getSpinProbablity = async () => {
  try {
    return await axios({
      method: "GET",
      url: ApiConfig?.spinProbablity,
      headers: {
        token: window.sessionStorage.getItem("token"),
      },
    });
  } catch (error) {
    console.log(error);
    return error?.response;
  }
};

export const updateSpinStatus = async () => {
  try {
    return await axios({
      method: "POST",
      url: ApiConfig?.updateSpinStatus,
      headers: {
        token: window.sessionStorage.getItem("token"),
      },
    });
  } catch (error) {
    console.log(error);
    return error?.response;
  }
};
export const updateSpin = async () => {
  try {
    return await axios({
      method: "get",
      url: ApiConfig?.updateSpin,
      headers: {
        token: window.sessionStorage.getItem("token"),
      },
    });
  } catch (error) {
    console.log(error);
    return error?.response;
  }
};

export const jackpotWinner = async (winAmount, isWin = true, type) => {
  try {
    return await axios({
      method: "POST",
      url: ApiConfig?.jackpotWinner,
      headers: {
        token: window.sessionStorage.getItem("token"),
      },
      data: {
        isAmount: isWin,
        amount: winAmount,
        type: type,
      },
    });
  } catch (error) {
    console.log(error);
    return error?.response;
  }
};

export const spinResult = async (winAmount, isWin = true) => {
  try {
    return await axios({
      method: "POST",
      url: ApiConfig?.spinResultApi,
      headers: {
        token: window.sessionStorage.getItem("token"),
      },
      data: {
        isWin: isWin,
        potAmount: winAmount,
      },
    });
  } catch (error) {
    console.log(error);
    return error?.response;
  }
};

export const updateBonusSpinCount = async () => {
  try {
    return await axios({
      method: "GET",
      url: ApiConfig?.updateBonusSpinApi,
      headers: {
        token: window.sessionStorage.getItem("token"),
      },
    });
  } catch (error) {
    console.log(error);
    return error?.response;
  }
};

export const purchaseSpin = async (amount) => {
  try {
    return await axios({
      method: "POST",
      url: ApiConfig?.purchaseJackpot,
      headers: {
        token: window.sessionStorage.getItem("token"),
      },
      data: {
        amount: +amount,
      },
    });
  } catch (error) {
    console.log(error);
    return error?.response;
  }
};

export const playerPlayedStatus = async () => {
  try {
    return await axios({
      method: "GET",
      url: ApiConfig?.getPlayerPlayedStatus,
      headers: {
        token: window.sessionStorage.getItem("token"),
      },
    });
  } catch (error) {
    console.log(error);
    return error?.response;
  }
};
